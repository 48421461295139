/* eslint-disable */
import { i18n } from '@/locales/i18n';
import IdField from '@/shared/fields/id-field';
import StringField from '@/shared/fields/string-field';
import JsonField from '@/shared/fields/json-field';
import { GenericModel } from '@/shared/model/generic-model';

function label(name) {
  return i18n(`iam.fields.${name}`);
}

const fields = {
  id: new IdField('id', label('id')),
  en: new StringField('en', label('en')),
  ar: new StringField('ar', label('ar')),
  image: new StringField('image', label('image')),
  name: new JsonField('name', label('name'), ['en', 'ar']),
};

export class ServicesTypeModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
