<template>
  <div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Form Layout -->
        <div class="intro-y box p-5">
          <h2 class="text-lg font-medium mr-auto border-b pb-5">
            <app-i18n
              code="servicesType.edit.title"
              v-if="isEditing"
            ></app-i18n>
            <app-i18n code="servicesType.new.title" v-else></app-i18n>
          </h2>
          <section class="relative">
            <div
              style="width: 100%; height: 80vh"
              class="flex justify-center"
              v-if="findLoading"
            >
              <LoadingIcon icon="grid" color="#2D3748" style="width: 100px" />
            </div>
            <div v-if="model">
              <div class="flex gap-6 sm:flex-row flex-col">
                <div class="grid grid-cols-12 mt-5 flex-1">
                  <div class="col-span-12">
                    <!-- <input
                      id="crud-form-1"
                      type="text"
                      class="form-control w-full dark:bg-dark-9"
                      v-model="model.name.en"
                      :placeholder="i18n('servicesType.edit.nameInEnglish')"
                    /> -->
                    <label for="" class="mb-5">{{
                      i18n('servicesType.edit.nameInEnglish')
                    }}</label>
                    <EmojiPicker
                      :native="true"
                      @update:text="onChangeEn"
                      pickerType="input"
                      :class="isRTL ? 'rtl' : ''"
                    />
                  </div>
                </div>
                <div class="grid grid-cols-12 mt-5 flex-1">
                  <div class="col-span-12">
                    <label class="mb-5">{{
                      i18n('servicesType.edit.nameInArabic')
                    }}</label>
                    <!-- <input
                      id="crud-form-1"
                      type="text"
                      class="form-control w-full dark:bg-dark-9"
                      v-model="model.name.ar"
                      :placeholder="i18n('servicesType.edit.nameInArabic')"
                    /> -->
                    <EmojiPicker
                      :native="true"
                      @update:text="onChangeAr"
                      pickerType="input"
                      :class="isRTL ? 'rtl' : ''"
                    />
                  </div>
                </div>
              </div>
              <div class="grid grid-cols-12 gap-5 mt-5 items-center">
                <h4
                  class="text-lg font-medium leading-none items-center mr-2 lg:col-span-2 mb-3"
                >
                  {{ i18n('project.fields.image') }}
                </h4>
                <div class="lg:col-span-10 col-span-12 lg:mt-0 mt-2">
                  <div class="relative" v-if="isEditing && logoUrl">
                    <div class="flex gap-0 flex-wrap">
                      <div class="relative h-32 w-36">
                        <img
                          :src="logoUrl"
                          class="object-cover h-full w-full"
                        />
                        <img
                          src="/images/deleteIcon.png"
                          alt=""
                          class="delete-icon w-12 h-12 absolute bottom-0 left-0 cursor-pointer"
                          @click="deleteImage()"
                        />
                      </div>
                    </div>
                  </div>
                  <Uploader ref="uploaderLogo" v-else></Uploader>
                </div>
              </div>
              <!-- END -->
              <div class="flex justify-center items-center mt-10">
                <AppButton
                  type="button"
                  class="btn bg-theme-31 text-white cursor-pointer"
                  :class="!saveLoading ? 'w-24' : ''"
                  :disabled="saveLoading || findLoading"
                  :loading="saveLoading"
                  @click="doSubmit()"
                >
                  <strong>{{ i18n('common.save') }}</strong>
                  <template v-slot:loading>
                    <app-i18n code="common.loading"></app-i18n>
                    <LoadingIcon
                      icon="three-dots"
                      color="#FFFFFF"
                      style="margin: 0 4px"
                    />
                  </template>
                </AppButton>
                <button
                  type="button"
                  class="
                    btn
                    bg-theme-32
                    text-black
                    w-24
                    ml-3
                    mr-3
                    cursor-pointer
                  "
                  @click="doCancel()"
                >
                  <app-i18n code="common.cancel"></app-i18n>
                </button>
              </div>
            </div>
          </section>
        </div>
        <!-- END: Form Layout -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import Message from '@/shared/message/toastify'
import { FormSchema } from '@/shared/form/form-schema'
import { ServicesTypeModel } from '@/store/services-type/services-type-model'
import EmojiPicker from 'vue3-emoji-picker'
import 'vue3-emoji-picker/dist/style.css'
import Uploader from '@/views/services-type/uploader.vue'

const { fields } = ServicesTypeModel
const formSchema = new FormSchema([fields.id, fields.name])

export default defineComponent({
  components: {
    Uploader,
    EmojiPicker
  },
  props: {
    id: {
      type: String,
      required: false
    }
  },
  setup() {
    const errorMessage = ref('')

    return {
      errorMessage
    }
  },
  data() {
    return {
      model: null,
      rules: formSchema.rules()
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      record: 'servicesType/form/record',
      findLoading: 'servicesType/form/findLoading',
      saveLoading: 'servicesType/form/saveLoading'
    }),
    isEditing() {
      return !!this.id
    },
    filesToSaveLogo() {
      return this.$refs.uploaderLogo?.filesToSave || []
    },
    logoUrl() {
      return this.model && this.model.logo && this.model.logo.length > 0
        ? this.model.logo
        : null
    }
  },
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.servicesType')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.servicesType')
    }
  },
  async mounted() {
    if (this.isEditing) {
      await this.doFind(this.id)
    } else {
      this.doNew()
    }

    this.model = formSchema.initialValues(this.record || {})
    if (this.isEditing) {
      this.model.name = {
        en: this.record.name.en,
        ar: this.record.name.ar
      }
      this.model.logo = this.record.logo || null
    }
  },
  methods: {
    ...mapActions({
      doNew: 'servicesType/form/doNew',
      doFind: 'servicesType/form/doFind',
      doCreate: 'servicesType/form/doCreate',
      doUpdate: 'servicesType/form/doUpdate'
    }),
    deleteImage() {
      this.model.logo = null
    },
    i18n(key, args) {
      return this.$t(key, args)
    },
    isFormValid() {
      if (this.model.name.en.length == 0 || this.model.name.ar.length == 0) {
        this.errorMessage = 'servicesType.edit.errors.emptyFields'
        return false
      }
      if (this.isEditing) {
        if (!(this.logoUrl || this.filesToSaveLogo.length !== 0)) {
          this.errorMessage = 'notifications.create.popup.emptyFields'
          return false
        }
      } else {
        if (this.filesToSaveLogo.length === 0) {
          this.errorMessage = 'notifications.create.popup.emptyFields'
          return false
        }
      }
      return true
    },
    doCancel() {
      this.$router.push('/servicesType')
    },
    doReset() {
      this.model = formSchema.initialValues(this.record)
    },
    onChangeEn(text) {
      this.model.name.en = text
    },
    onChangeAr(text) {
      this.model.name.ar = text
    },
    async doSubmit() {
      if (!this.isFormValid()) {
        const ERROR = this.i18n(this.errorMessage)
        return Message.error(ERROR)
      }

      // const { id, ...values } = formSchema.cast(this.model)
      const { id, name } = formSchema.cast(this.model)
      if (this.isEditing) {
        await this.doUpdate({
          id,
          values: { name, logo: this.logoUrl || this.filesToSaveLogo[0] }
        })
      } else {
        await this.doCreate({ name, logo: this.filesToSaveLogo[0] })
      }
    }
  }
})
</script>

<style>
.v3-emoji-picker-input {
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-color: rgba(226, 232, 240, var(--tw-border-opacity)) !important;
  border-radius: 0.375rem !important;
  border-width: 1px !important;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.v3-input-emoji-picker.rtl
  .v3-input-picker-root
  .v3-input-picker-wrap
  .v3-input-picker-icon {
  left: 5px !important;
  right: unset !important;
}
</style>
